.carousel-control-prev {left: -1% !important;}
.carousel-control-next {right: -1% !important;}

@media only screen and (min-width: 500px) {
  .carousel-control-prev {left: -3% !important;}
  .carousel-control-next {right: -3% !important;}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.selectedThumbnail {
  border: 5px;
  border-style: solid;
  color: lime;
}

.boxBorderShadow {
  border-radius: 20px !important;
  box-shadow: 0px 2px 20px 0px rgba(0, 255, 255, 0.5);
}
.btnBorderShadow {
  border-radius: 20px !important;
  box-shadow: 0px 2px 20px 0px rgba(40, 167, 69, 0.7);
}
.headerBTNBorderShadow {
  border-radius: 20px !important;
  color: blue !important;
  background-color: blue;
  background-image:
    radial-gradient(
      rgba(0, 255, 255, 1) 10%,
      transparent 75%
    ) !important;
}

.navStyle {
  background-color: #BFFFFF;
}

.cellBorders {border: 1px solid #00FFFF;}
.cellBorderL {border-left: 1px solid #00FFFF;}
.cellBorderR {border-right: 1px solid #00FFFF;}
.topBordersRounded {border-radius: 20px 20px 0px 0px !important;}
.bottomBordersRounded {border-radius: 0px 0px 20px 20px !important;}